import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent {
  assessment_id: number
  training: object[] = [];
  recommendations: object[] = [];
  assessment: object = {};
  results_context: object = {};
  actionText: object = {};
  selectedRecommendations: string[] = [];
  includeNotes: boolean = true;

  hasApiError = false;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private assessments: AssessmentsService,
    private router: Router) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const a_id = params.get('assessment_id');
      if(a_id) {
        var path = '';

        this.route.queryParams.subscribe(params => {
          path = params.path;
        });

        this.assessmentResults(a_id, path);
      }
    })
  }

  assessmentResults(assessment_id, path) {
    this.assessments.assessmentResults$(assessment_id, path).subscribe({
      next: (res) => {
        this.loadResults(res);
        this.isLoading = false;
      },
      error: () => {
        this.hasApiError = true;
        this.isLoading = false;
      }
    });
  }

  selectRecommendation(value: string) {
    if (this.selectedRecommendations.includes(value)) {
      this.selectedRecommendations = this.selectedRecommendations.filter((item) => item !== value);
    } else {
      this.selectedRecommendations.push(value);
    }
  }

  loadResults(res) {
    this.assessment_id = res['assessment'];
    this.training = res['training'];
    this.recommendations = res['recommendations'];
    this.hasApiError = false;
    this.results_context = res['_context'];
    this.assessment = res['_context']['assessment'];
    // this.marker_top = 100 - ((parseInt(this.assessment['scores']['score'])) / 5 * 100);
    //
    res['recommendations'].map(
      (x) => { x['recommendations'].map(
        (y) => { this.selectedRecommendations.push(y['id']); }
      )}
    );

    this.assessments.addSessionContext(res['_context']);
    this.assessments.setSidebar(res['sidebar']);
  }

  setActionText(path, event) {
    this.actionText[path] = event.target.value;
  }
  saveAction(path: string) {
    console.log(path);
    this.assessments.addAction$(
      this.results_context['assessment']['id'],
      path,
      this.actionText[path]
    ).subscribe({
      next: (res) => {
        this.hasApiError = false;
        this.router.navigateByUrl('/');
      },
      error: () => this.hasApiError = true,
    });
  }

  downloadReport(event) {
    document.body.classList.add('waiting');
    event.target.disabled = true;

    this.assessments.getPdfReport$(
      this.results_context['assessment']['id'],
      this.selectedRecommendations,
      this.includeNotes,
    ).subscribe({
      next: (data) => {
        this.hasApiError = false;
        event.target.disabled = false;
        document.body.classList.remove('waiting');

        const blob = new Blob([data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);

        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "maturity-assessment.pdf";
        link.click();
        },
      error: () => {
        this.hasApiError = true;
        event.target.disabled = false;
        document.body.classList.remove('waiting');
      }
    });
  }

  setIncludeNotes(event) {
    this.includeNotes = event.target.checked;
  }
}
