import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js/auto';


@Component({
  selector: 'app-score-breakdown',
  templateUrl: './score-breakdown.component.html',
  styleUrl: './score-breakdown.component.css'
})
export class ScoreBreakdownComponent {
  public chartjs: any;

  @Input() scores: object = {};

  Object = Object; // used to enumerate keys in the template
  markerTop: number = 0;
  markerGapAdjustment: number = 50;
  levelCount: number = 5;
  chartHeight: string = '250px';
  resultsView: string = '0'; // 0 = overall, 1 = summary, 2 = counts, 3 = percent, 4 = score by topic

  ngOnChanges(changes): void {
    this.levelCount = Object.keys(this.scores['breakdown']['levels']).length;
    this.markerTop = 100 - ((parseInt(this.scores['score'])) / this.levelCount * 100);
    this.markerGapAdjustment = 44 + (this.scores['score'] * 1.5);
    this.chartHeight = this.levelCount * 50 + 'px';
  }

  renderTopicScoresIfNeeded() {
    if(this.resultsView == '4' && !this.chartjs) {
      this.renderTopicScores();
      this.chartjs.canvas.parentNode.style.height = this.levelCount * 50 + 30 + 'px'
      this.chartjs.canvas.parentNode.style.width = '600px'
    }
  }

  renderTopicScores() {
    // console.log('rendering topic scores...');
    if(this.chartjs) {
      this.chartjs.destroy();
    }

    const data = {
      labels: Object.keys(this.scores['breakdown']['topics']),
      datasets: [{
        label: '',
        data: this.scores['breakdown']['scores_by_topic'],
        backgroundColor: this.scores['breakdown']['scores_by_topic'].map(
          (x) => {
            switch (x) {
              case 0: return '#4c4c4c';
              case 1: return '#00addb';
              case 2: return '#d25d3a';
              case 3: return '#e59649';
              case 4: return '#039345';
            }
          }
        ),
      }]
    };

    this.chartjs = new Chart('topic-scores', {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            max: 4,
            display: true,
            ticks: {
              display: false,
              stepSize: 1,
            },
            grid: { drawTicks: false },
            border: { display: false },
          },
          x: {
            grid: { display: false }
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                const code = tooltipItems[0]['label'];
                return code + ': ' + this.scores['breakdown']['topics'][code];
              },
              label: (context) => {
                const level = context.formattedValue;
                return 'Level ' + level + ' (' + this.scores['breakdown']['levels'][level] + ')';
              },
            }
          }
        }
      },
    });
  }
}
